import React, { useState, useEffect } from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { useTranslation } from "react-i18next";

export default function LanguageButton() {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(secureLocalStorage.getItem("lang") || "de");

  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);

  const handleChange = (event) => {
    const newLang = event.target.value;
    setLang(newLang);
    changeLanguage(newLang);
  };

  function changeLanguage(e) {
    i18n.changeLanguage(e);
    secureLocalStorage.setItem("lang", e);
  }

  const languages = ["en", "de"];

  return (
    <FormControl
      size="small"
      variant="outlined"
      sx={{
        width: "100%",
        backgroundColor: "#292930",
        padding: 0,
        margin: 0,
        borderRadius: "8px",
        "& .MuiInputBase-root": {
          padding: 0,
        },
        "& .MuiSelect-select": {
          padding: "6px",
          textAlign: "start",
          color: "#ffffff",
          fontSize: '14px',
        },
        "& .MuiSelect-icon": {
          color: "#ffffff",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(0, 0, 0, 0.23)",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#aaa",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#3f51b5",
        },
      }}
    >
      <Select
        value={lang}
        onChange={handleChange}
        displayEmpty
        sx={{
          textAlign: "start",
          color: "#ffffff",
          "& .MuiSvgIcon-root": {
            color: "#ffffff",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: "#292930",
              color: "#ffffff",
              "& .MuiMenuItem-root": {
                "&.Mui-selected": {
                  backgroundColor: "#3f51b5",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#303f9f",
                  },
                },
                "&:hover": {
                  backgroundColor: "#3a3b3f",
                },
              },
              "& .MuiMenu-list": {
               backgroundColor: '#292930',
              },
            },
          },
        }}
      >
        {languages.map((el) => (
          <MenuItem key={el} value={el}>
            {el.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
